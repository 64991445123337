import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Acompanhamento from './pages/Acompanhamento';
import StatusPedidos from './pages/StatusPedidos';
import Principal from './pages/Principal';

function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/order/:orderId" exact component={Principal} />
                <Route path="/acompanhamento" component={Acompanhamento} />
                <Route path="/statuspedidos" component={StatusPedidos} />
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;