import React from "react"
import ContentLoader from "react-content-loader"

const PrincipalLoader = (props: any) => (
  <ContentLoader 
    speed={2}
    width={1000}
    height={300}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="74" y="120" rx="0" ry="0" width="2" height="4" /> 
    <rect x="184" y="51" rx="0" ry="0" width="1" height="0" /> 
    <rect x="24" y="15" rx="0" ry="0" width="56" height="58" /> 
    <rect x="86" y="16" rx="0" ry="0" width="56" height="58" /> 
    <rect x="24" y="120" rx="0" ry="0" width="93" height="11" /> 
    <rect x="383" y="9" rx="0" ry="0" width="96" height="11" /> 
    <rect x="24" y="160" rx="0" ry="0" width="244" height="27" /> 
    <rect x="26" y="206" rx="0" ry="0" width="130" height="12" /> 
    <rect x="384" y="38" rx="0" ry="0" width="139" height="42" /> 
    <rect x="386" y="104" rx="0" ry="0" width="185" height="30" /> 
    <rect x="400" y="195" rx="0" ry="0" width="132" height="24" />
  </ContentLoader>
)

export default PrincipalLoader

