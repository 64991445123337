import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import DotsDisabledIcon from "../images/dots-gray-icon.svg";
import DotsEnabledIcon from "../images/dots-pink-icon.svg";

import ShoppingCartComplete from "../images/shopping-cart-pink.svg";
import BoxComplete from "../images/box-pink.svg";
import ThumbsUpComplete from "../images/thumb-up-pink.svg";
import CarComplete from "../images/car-pink.svg";

import ShoppingCartActive from "../images/shopping-cart-yellow.svg";
import BoxActive from "../images/box-yellow.svg";
import ThumbsUpActive from "../images/thumb-up-yellow.svg";
import CarActive from "../images/car-yellow.svg";
import HouseActive from "../images/house-yellow.svg";

import ShoppingCartInactive from "../images/shopping-cart-gray.svg";
import BoxInactive from "../images/box-gray.svg";
import ThumbsUpInactive from "../images/thumb-up-gray.svg";
import CarInactive from "../images/car-gray.svg";
import HouseInactive from "../images/house-gray.svg";

import RetiradaInactive from "../images/retirada-icon.svg";
import RetiradaActive from "../images/retirada-yellow-icon.svg";

import CanceladoActive from '../images/cancel-icon.svg';

import carInactiveOnly from '../images/car-gray-only.svg';
import carActiveOnly from '../images/car-yellow-only.svg';

import carCompleteOnly from '../images/car-pink-only.svg';

import "../styles/pages/detentrega.css";

import { logEntregaProps } from "../interfaces/index";

import apiERP from "../services/api";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

interface DetEntregaProps {
  logsEntrega: Array<logEntregaProps>;
}

export default function DetEntrega(props: DetEntregaProps) {
  const classes = useStyles();
  const [dotsInvoiced, setDotsInvoiced] = useState(DotsDisabledIcon);
  const [dotsHandling, setDotsHandling] = useState(DotsDisabledIcon);
  const [dotsCourier, setDotsCourier] = useState(DotsDisabledIcon);
  const [dotsDelivered, setDotsDelivered] = useState(DotsDisabledIcon);

  const incompleteColor: string = '#757575';
  const completeColor: string = '#BA0E4A';

  const [dotsMediaInvoiced, setMediaDotsInvoiced] = useState(incompleteColor);
  const [dotsMediaHandling, setMediaDotsHandling] = useState(incompleteColor);
  const [dotsMediaCourier, setMediaDotsCourier] = useState(incompleteColor);
  const [dotsMediaDelivered, setMediaDotsDelivered] = useState(incompleteColor);

  const [recebidoIcon, setRecebidoIcon] = useState(ShoppingCartInactive);
  const [separacaoIcon, setSeparacaoIcon] = useState(BoxInactive);
  const [separadoIcon, setSeparadoIcon] = useState(ThumbsUpInactive);
  const [transportadoraIcon, setTransportadoraIcon] = useState(carInactiveOnly);
  const [entregueIcon, setEntregueIcon] = useState(HouseInactive);
  const [retiradaIcon, setRetiradaIcon] = useState(RetiradaInactive);

  const [coletado, setColetado] = useState(false);
  const [complete, setComplete] = useState(false);
  const [programacao, setProgramacao] = useState("");
  const [transportadorasColeta, setTransportadorasColeta] = useState(['55', '66', '67', '68']);

  useEffect(() => {
    let statusOrder: Array<string> = [];
    props.logsEntrega.map(
      (logEntrega) =>
        logEntrega.descricao && statusOrder.push(logEntrega.descricao)
    );
    handleStatusDotsIcon(statusOrder, props.logsEntrega[0].isRetirada);
  }, [props.logsEntrega]);

  function handleStatusDotsIcon(
    statusOrder: Array<string>,
    isRetirada: boolean | undefined
  ) {
    let programaca = ""
    let setColeta = false
    let transp = null
    let chItemPed = null

    if (statusOrder.includes("Preparando Entrega")) {
      setRecebidoIcon(ShoppingCartActive);
    }

    props.logsEntrega.map((log) => {
      if (log) {
        if (log.dtEntregaAg) {
          if (programaca == "") {
            programaca = log.dtEntregaAg
            setProgramacao(log.dtEntregaAg)
          }
          }
        if (log.transportadora) {
          if (transportadorasColeta.indexOf(log.transportadora.toString()) > -1) {
            transp = log.transportadora
          }
        }
        chItemPed = log.chItemPed ? log.chItemPed : null
      }
    })

    if (statusOrder.includes("Faturado")) {
      setDotsHandling(DotsEnabledIcon);
      setMediaDotsHandling(completeColor);

      setDotsInvoiced(DotsEnabledIcon);
      setMediaDotsInvoiced(completeColor);

      setRecebidoIcon(ShoppingCartComplete);
      setSeparacaoIcon(BoxComplete);
      setSeparadoIcon(ThumbsUpActive);

      if (isRetirada) {
        setDotsHandling(DotsEnabledIcon);
        setMediaDotsHandling(completeColor);

        setDotsInvoiced(DotsEnabledIcon);
        setMediaDotsInvoiced(completeColor);

        setDotsCourier(DotsEnabledIcon);
        setMediaDotsCourier(completeColor);

        setDotsDelivered(DotsEnabledIcon);
        setMediaDotsDelivered(completeColor);

        setSeparacaoIcon(BoxComplete);
        setSeparadoIcon(ThumbsUpComplete);
        setRetiradaIcon(RetiradaActive);
      }

      if (!isRetirada && programaca) {
        setSeparadoIcon(ThumbsUpComplete);

        setDotsCourier(DotsEnabledIcon);
        setMediaDotsCourier(completeColor);

        setTransportadoraIcon(carInactiveOnly);
      } else if (!isRetirada && props.logsEntrega[0].dtEntregaAg == "") {
        setSeparadoIcon(ThumbsUpComplete);

        setDotsCourier(DotsEnabledIcon);
        setMediaDotsCourier(completeColor);

        setTransportadoraIcon(CarActive);
      }
    }

    if (statusOrder.includes("Entregue a transportadora")) {
      setRecebidoIcon(ShoppingCartComplete);
      setSeparacaoIcon(BoxComplete);
      setSeparadoIcon(ThumbsUpComplete);

      setDotsHandling(DotsEnabledIcon);
      setMediaDotsHandling(completeColor);

      setDotsInvoiced(DotsEnabledIcon);
      setMediaDotsInvoiced(completeColor);

      setDotsCourier(DotsEnabledIcon);
      setMediaDotsCourier(completeColor);

      if (!isRetirada) {
        if (programaca) {
          setTransportadoraIcon(carActiveOnly);
        } else {
          setTransportadoraIcon(CarActive);
        }
        setColetado(true);
      }
    }

    if (
      statusOrder.includes("ENTREGA REALIZADA") ||
      statusOrder.includes("Entrega Realizada Normalmente") ||
      statusOrder.includes("Entrega Fora da Data Programada") ||
      statusOrder.includes("Entrega com Indenização Efetuada") ||
      statusOrder.includes("Entregue") ||
      statusOrder.includes("ENTREGA REALIZADA COM SUCESSO")
    ) {
      setDotsHandling(DotsEnabledIcon);
      setMediaDotsHandling(completeColor);

      setDotsInvoiced(DotsEnabledIcon);
      setMediaDotsInvoiced(completeColor);

      setDotsCourier(DotsEnabledIcon);
      setMediaDotsCourier(completeColor);

      setDotsDelivered(DotsEnabledIcon);
      setMediaDotsDelivered(completeColor);

      setRecebidoIcon(ShoppingCartComplete);
      setSeparacaoIcon(BoxComplete);
      setSeparadoIcon(ThumbsUpComplete);
      if (programaca) {
        setTransportadoraIcon(carCompleteOnly);
      } else {
        setTransportadoraIcon(CarComplete);
      }
      setEntregueIcon(HouseActive);
      setComplete(true);

      if (!isRetirada) {
        setColetado(true);
      }
    }
    if (chItemPed && !setColeta && transp) {
      getColeta(chItemPed).then((result) => {
        programaca = result
        setProgramacao(result)
      })
    }
  }

  function handleFormatData(date: string) {
    const opcoesData: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    let dataFormat = new Date(date);
    return dataFormat.toLocaleDateString("pt-br", opcoesData).split(" ");
  }

  async function getColeta(chItemPed: Number) {
    const response = await apiERP.get(`/pedidos/coleta/${chItemPed}`);
    if (response.status == 200 && response.data) return response.data
  }

  var dataAtual = new Date();
  dataAtual.setHours(0, 0, 0, 0);

  return (
    <div className={classes.root}>
      {props.logsEntrega[0].descricao === "Cancelado" ? (
        <div className="detEntrega-content cancel">
          <img src={CanceladoActive} alt="pedido-cancelado" />
        </div>
      ) : (
        <div className="detEntrega-content">
          <div
            className={
              props.logsEntrega[0].isRetirada
                ? "detEntrega-content-top-retirada"
                : "detEntrega-content-top"
            }
          >

          </div>
          <div className="detEntrega-content-middle">
            <div className="detEntrega-status-pedido">
              <img src={recebidoIcon} alt="pedido-recebido" />
            </div>

            <div className="detEntrega-dots-up">
              <img className="dots-img" src={dotsInvoiced} alt="dots-disabled" />
            </div>
            <div className="detEntrega-dots">
              <div style={{ color: dotsMediaInvoiced }}>.....</div>
            </div>

            <div className="detEntrega-status-pedido">
              <img src={separacaoIcon} alt="pedido-separacao" />
            </div>

            <div className="detEntrega-dots-down">
              <img className="dots-img" src={dotsHandling} alt="dots-disabled" />
            </div>
            <div className="detEntrega-dots">
              <div style={{ color: dotsMediaHandling }}>.....</div>
            </div>

            <div className="detEntrega-status-pedido">
              <img src={separadoIcon} alt="pedido-separado" />
            </div>

            <div className="detEntrega-dots-up">
              <img className="dots-img" src={dotsCourier} alt="dots-disabled" />
            </div>
            <div className="detEntrega-dots">
              <div style={{ color: dotsMediaCourier }}>.....</div>
            </div>

            {props.logsEntrega[0].isRetirada ? (
              <div className="detEntrega-status-pedido content-retirada">
                <img
                  src={retiradaIcon}
                  alt="pedido-retirada"
                  className="icon-retirada"
                />
                {props.logsEntrega[0].dtRetirada ? (
                  <>
                    {[6, 301, 314].indexOf(props.logsEntrega[0].status) > -1 ? (
                      <>
                        <span className="legenda-retirada">Retirar a partir: </span>
                        <span className="legenda-retirada">
                          {handleFormatData(props.logsEntrega[0].dtRetirada)}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="legenda-retirada-previa">Previsão de retirada: </span>
                        <span className="legenda-retirada-previa">
                          {handleFormatData(props.logsEntrega[0].dtRetirada)}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <span className="legenda-retirada-indisponivel">
                      RETIRADA INDISPONÍVEL{" "}
                    </span>
                  </>
                )}
              </div>
            ) : (
              <>
                {!coletado && (
                  <>
                    <div className="detEntrega-status-pedido status-coleta">
                      <img src={transportadoraIcon} alt="pedido-transportadora" className="icon-entrega" />
                      {programacao != "" &&
                        <>
                          <span className="legenda-coleta">Coleta prevista: </span>
                          <span className="legenda-coleta">
                            {handleFormatData(programacao)}
                          </span>
                        </>
                      }
                      {programacao == "" &&
                        <span className="legenda-coleta-indisponivel">Aguardando previsão de coleta</span>
                      }
                    </div>
                  </>
                )
                }

                {coletado && programacao != "" && (
                  <>
                    <div className="detEntrega-status-pedido div-detEntrega-coletado">
                      <img src={transportadoraIcon} alt="pedido-transportadora" className="icon-entrega" />
                      {programacao != "" &&
                        <>
                          {!complete &&
                            <>
                              <span className="legenda-coletado-incompleto">{dataAtual <= new Date(props.logsEntrega[0].dtEntregaAg) ? "Coletado em:" : "Data coleta: "}</span>
                              <span className="legenda-coletado-incompleto">
                                {handleFormatData(programacao)}
                              </span>
                            </>
                          }
                          {complete &&
                            <>
                              <span className="legenda-coletado-completo">Coletado em: </span>
                              <span className="legenda-coletado-completo">
                                {handleFormatData(programacao)}
                              </span>
                            </>
                          }
                        </>
                      }
                    </div>
                  </>
                )
                }
                {coletado && programacao == "" && (
                  <>
                    <div className="detEntrega-status-pedido">
                      <img src={transportadoraIcon} alt="pedido-transportadora" />
                    </div>
                  </>
                )
                }

                <div className="detEntrega-dots-down">
                  <img className="dots-img" src={dotsDelivered} alt="dots-disabled" />
                </div>

                <div className="detEntrega-dots">
                  <div style={{ color: dotsMediaDelivered }}>.....</div>
                </div>
                <div className="detEntrega-status-pedido">
                  <img src={entregueIcon} alt="pedido-entregue" />
                </div>

              </>
            )}
          </div>
          <div
            className={
              props.logsEntrega[0].isRetirada
                ? "detEntrega-content-bottom-retirada"
                : "detEntrega-content-bottom"
            }
          >
          </div>
        </div>
      )}
    </div>
  );
}
