import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import iconeNfe from "../images/page-icon.svg";
import "../styles/pages/detgrid.css";

import DetEntrega from "./DetEntrega";
import { logEntregaProps } from "../interfaces/index";
import { MouseEvent } from "react";

import api from "../services/api";

import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { red, blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

interface PropriedadesDetGrid {
  logsEntrega: Array<logEntregaProps>;
}

export default function DetGrid(props: PropriedadesDetGrid) {
  const classes = useStyles();

  const [search, setSearch] = useState(Boolean);
  const [link, setLink] = useState("");
  const [target, setTarget] = useState("");
  const [display, setDisplay] = useState("none");
  const [displayButton, setDisplayButton] = useState("flex");
  const [displaySearch, setDisplaySearch] = useState("none");
  
  let cnpjsToNFE = ['26796309000123',  /* zeni */
                    '34859169000202',  /* juma */
                    '10935713000109', /* eco */
                    '10474874000134', /* trip */
                    '34859169000113', /* juma comercio */
                    '10406052000116' /* gaia */
                  ]

  async function getNFE(e:MouseEvent, chave: Number, chItemPed: Number, transportadora: String) {
    if (link !== "") return
    try {
      e.preventDefault();
      handleButtonNota(true, false);
      const response = await api.post("/linknfe", { chave, chItemPed, transportadora});
      handleButtonNota(false, false);
      
      if (response.data.nfe) {
        handleButtonNota(false, true);
        setLink(response.data.nfe)
        window.open(response.data.nfe)
      } else {
        handleButtonNota(false, false);

      }
    } catch(error) {
        setLink("")
        setTarget("")
        setDisplay("flex")
        setDisplayButton("none")
    }
  }

  function handleButtonNota(type: Boolean, hasLink: Boolean) {
    if (type == true) {
      setSearch(true);
      setDisplaySearch("flex");
      setDisplayButton("none");
    } else {
      if (hasLink) {
        setTarget("_blank");
        setDisplay("none");
        setDisplayButton("flex");
      } else {
        setSearch(false);
        setTarget("");
        setDisplay("flex");
        setDisplayButton("none");
        setDisplaySearch("none");
      }
    }
  }

  function isFaturado() {
    let orderFat = {
      isFat: false,
      index: 0,
    };

    props.logsEntrega.map((logEntrega, index) => {
      if (logEntrega.chNFE) {
        orderFat.isFat = true;
        orderFat.index = index;
      }
    });

    return orderFat;
  }

  function getCnpjs() {
    return cnpjsToNFE.find((el) => el == props.logsEntrega[isFaturado().index].cnpjEmissorNFE)
  }

  return (
    <div className={classes.root}>
      <div className="detgrid-main">
        <DetEntrega logsEntrega={props.logsEntrega} />
      </div>
      <div className="detgrid-footer">
        <div className="detgrid-footer-content-right">
          {isFaturado().isFat && props.logsEntrega[0].descricao !== "Cancelado" && (
            <div className="detgrid-nfe">
              {props.logsEntrega[isFaturado().index].linkNFE && (
                <a
                  href={props.logsEntrega[isFaturado().index].linkNFE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconeNfe} alt="nfe"></img>
                  VISUALIZAR <br />
                  NOTA FISCAL
                </a>
              )}
              {!props.logsEntrega[isFaturado().index].linkNFE && getCnpjs() && (
                <a style={{display: displayButton}} href={link} target={target}
                  onClick={(e) => getNFE(e, props.logsEntrega[isFaturado().index].chave, props.logsEntrega[isFaturado().index].chItemPed, props.logsEntrega[isFaturado().index].transportadora)}
                >
                  <img src={iconeNfe} alt="nfe"></img>
                  VISUALIZAR <br />
                  NOTA FISCAL
                </a>
              )}
              <div className="detgrid-searchingNota" style={{display: displaySearch}}>
              <InfoIcon style={{ color: blue[500] }}></InfoIcon>
              <span>Buscando...</span>
              </div>
              <div className="detgrid-errorNota" style={{display: display}}>
              <ErrorIcon style={{ color: red[500] }}></ErrorIcon>
              <span>ERRO AO BUSCAR NOTA</span>
              </div>
            </div>
          )}
          {isFaturado().isFat && props.logsEntrega[0].descricao !== "Cancelado" && (
            <div className="detgrid-transportadora">
              <span>transportadora</span>
              <a
                href={props.logsEntrega[isFaturado().index].linkRastreioTransp}
                target="_blank"
                rel="noreferrer noopener"
              >
                <p>{props.logsEntrega[isFaturado().index].codigo}</p>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
