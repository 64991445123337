import React, { MouseEvent, useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";

import "../styles/pages/acompanhamento.css";
import api from "../services/api";

import { pedidoDetProps, logEntregaProps } from "../interfaces";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "orderId", headerName: "OrderId Vtex", width: 130 },
  { field: "sequence", headerName: "Sequence Vtex", width: 130 },
  { field: "chCriacao", headerName: "Chave Criação", width: 130 },
  { field: "statusOrderDesc", headerName: "Status Pedido", width: 130 },
  { field: "codigo", headerName: "Código Recurso", width: 130 },
  { field: "descricao", headerName: "Nome Recurso", width: 130 },
  { field: "quantidade", headerName: "Quantidade", width: 130 },
  { field: "valorUnitario", headerName: "Valor Un.", width: 130 },
  { field: "statusLogDesc", headerName: "Status Item Pedido", width: 130 },
  { field: "criadoEm", headerName: "Criado Em", width: 130 },
  { field: "dtEntregaAg", headerName: "Data de Programação", width: 130 },
  { field: "dtMontagem", headerName: "Data de Montagem", width: 130 },
  { field: "transportadoraDesc", headerName: "Transportadora", width: 130 },
  { field: "numeroNFE", headerName: "Número NF", width: 130 },
];

function Acompanhamento() {
  const [pedido, setPedido] = useState("");
  const [orderInfo, setOrderInfo] = useState([]);
  const [searching, setSearching] = useState(false);

  async function fetchData(e: MouseEvent) {
    try {
      e.preventDefault();
      if (!pedido) return;

      setSearching(true);
      const response = await api.post("/acompanhamento", { chave: pedido });
      setSearching(false);

      if (!response.data) setOrderInfo([]);

      const cabecalho = {
        chave: response.data.order.chave,
        orderId: response.data.order.orderId,
        sequence: response.data.order.sequence,
        chCriacao: response.data.order.chCriacao,
        statusOrderDesc: response.data.order.orderStatus.descricao,
      };

      const itens = response.data.order.pedidoDet.map(
        (itensResponse: pedidoDetProps) => {
          return {
            ...cabecalho,
            chaveItem: itensResponse.chave,
            codigo: itensResponse.codigo,
            descricao: itensResponse.descricao,
            quantidade: itensResponse.quantidade,
            valorUnitario: itensResponse.valorUnitario,
          };
        }
      );

      const logs = response.data.logsEntrega.map(
        (logsResponse: logEntregaProps, index: Number) => {
          const itemFiltered = itens.filter(
            (item: any) => item.chaveItem === logsResponse.chItemPed
          );
          return {
            id: index,
            ...itemFiltered[0],
            statusLogDesc: logsResponse.descricao,
            transportadoraDesc: logsResponse.codigo,
            numeroNFE: logsResponse.numeroNFE,
            criadoEm: new Date(logsResponse.created_at).toLocaleDateString(
              "pt-BR"
            ),
            dtEntregaAg: logsResponse.dtProgramacaoErp ? 
            new Date(logsResponse.dtProgramacaoErp).toLocaleDateString("pt-BR"):
            logsResponse.dtEntregaAg ? new Date(logsResponse.dtEntregaAg).toLocaleDateString("pt-BR") : ""
              ,
            dtMontagem: logsResponse.dtMontagem ? new Date(logsResponse.dtMontagem).toLocaleDateString(
              "pt-BR"
            ) : "",
          };
        }
      );

      setOrderInfo(logs);
    } catch (error) {
      console.log(error);
      setSearching(false);
    }
  }

  return (
    <div className="page-acompanhamento">
      <h4>Acompanhamento de pedidos</h4>
      <div className="search-content">
        <TextField
          id="search-textfield"
          label="Pedido"
          variant="outlined"
          size="small"
          value={pedido}
          onChange={(e) => setPedido(e.target.value)}
        />
        <Button variant="contained" onClick={(e) => fetchData(e)}>
          {searching ? "Buscando" : "Buscar"}
        </Button>
      </div>
      <div className="data-content">
        <DataGrid
          rows={orderInfo}
          columns={columns}
          pageSize={10}
          checkboxSelection
        />
      </div>
    </div>
  );
}

export default Acompanhamento;
