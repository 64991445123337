import React from "react";
import format from "date-fns/format";

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import GridItemCab from "./CabGrid";
import GridItemDet from "./DetGrid";
import { logEntregaProps, pedidoDetProps } from "../interfaces/index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginBottom: "24px",
    },
  })
);

interface AccordionDetailedProps {
  pedidoDet: pedidoDetProps;
  logsEntrega: Array<logEntregaProps>;
  previsaoEntrega: string;
  chCriacao: number;
  orderId: string;
}

export default function SimpleAccordion(props: AccordionDetailedProps) {
  const classes = useStyles();

  function handleDateFormat(data: string) {
    if (data) return format(new Date(data), "dd/MM/yyyy");
  }

  let dataRet
  let dtEntregaAg = props.logsEntrega[0].dtEntregaAg

  if (props.logsEntrega[0].isRetirada) {
    dataRet = props.logsEntrega[0] && props.logsEntrega[0].dtRetirada
    ? handleDateFormat(props.logsEntrega[0].dtRetirada)
    : ""
  }

  // if (props.logsEntrega[0].isRetirada && 
  //   (props.logsEntrega[0].transportadora == '31' ||
  //    props.logsEntrega[0].transportadora == '56')) {
  //   let dt = new Date(dtEntregaAg ? dtEntregaAg : "")
  //   dt.setDate(dt.getDate() + 3);
  //   if (dt.getDay() == 0) dt.setDate(dt.getDate() + 1);
  //   dataRet = dt ? handleDateFormat(dt.toString()) : ""
  // } else {
  //   dataRet = props.logsEntrega[0] && props.logsEntrega[0].dtRetirada
  //   ? handleDateFormat(props.logsEntrega[0].dtRetirada)
  //   : ""
  // }

  return (
    <div className={`${classes.root} accordion-content`}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <GridItemCab
            descricaoItem={props.pedidoDet.descricao}
            quantidadePreco={`${props.pedidoDet.quantidade} UNIDADE - R$ ${props.pedidoDet.valorUnitario}`}
            dataEntrega={handleDateFormat(props.previsaoEntrega)}
            dataMontagem={
              props.logsEntrega[0] && props.logsEntrega[0].dtMontagem
                ? handleDateFormat(props.logsEntrega[0].dtMontagem)
                : ""
            }
            dataEntregaAg={
              props.logsEntrega[0] && dtEntregaAg
                ? handleDateFormat(dtEntregaAg)
                : ""
            }
            dataRetirada={
              dataRet
            }
            isRetirada={props.logsEntrega[0] && props.logsEntrega[0].isRetirada}
            transportadora={props.logsEntrega[0] && props.logsEntrega[0].transportadora}
            isRoteiro={props.logsEntrega[0] && props.logsEntrega[0].isRoteiro}
            dtRotaEntrega={props.logsEntrega[0] && props.logsEntrega[0].dtRotaEntrega}
            zDataAgendada={
              props.logsEntrega[0] && props.logsEntrega[0].zDataAgendada
              ? handleDateFormat(props.logsEntrega[0].zDataAgendada)
              : ""
            }
            chCriacao={props.chCriacao}
            orderId={props.orderId}
            status={props.logsEntrega[0] && props.logsEntrega[0].status}
            codigo={props.pedidoDet.codigo}
          ></GridItemCab>
        </AccordionSummary>
        <AccordionDetails>
          <GridItemDet logsEntrega={props.logsEntrega}></GridItemDet>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
